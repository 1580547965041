
.myLayout {
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'space-between';
}
.iconCol {
  flex: 1
}
.installTextCol {
  flex: 3
}
.lastAttemptCol {
  flex: 2
}
.failureCol {
  flex: 5;
}
.retryCol {
  flex: 1
}